<template>
  <div class="arrc-anchor-univ-map">
    <l-map class="arrc-anchor-univ-map__map" ref="arrc_anchor_univ_map"
           :zoomControl="leaflet_settings.zoomControl"
           :zoomSnap="leaflet_settings.zoomSnap">
      <l-tile-layer
          :url="leaflet_settings.tileLayer.url"
          :subdomains="leaflet_settings.tileLayer.subdomains"
          :attribution="leaflet_settings.tileLayer.attribution"
          :maxZoom="leaflet_settings.tileLayer.maxZoom"
      ></l-tile-layer>
        <l-marker @click="activateInstitution(institution.unitid)"
                  v-for="institution in univ_data"
                  :key="institution.unitid"
                  :lat-lng="latlng(institution.Latitude, institution.Longitude)">
          <l-icon v-if="institution.unitid !== active_ipeds"
              :icon-url="leaflet_settings.map_marker">
          </l-icon>
          <l-icon v-else
                  :icon-url="leaflet_settings.selected_map_marker">
          </l-icon>
        </l-marker>
    </l-map>
    <div class="arrc-anchor-univ-map__sidebar-wrapper">
      <div class="arrc-anchor-univ-map__sidebar-border-outer-radius">
        <div class="arrc-anchor-univ-map__sidebar-border-inner-radius">
          <div class="arrc-anchor-univ-map__sidebar-border-inner-radius-background">
            <div class="arrc-anchor-univ-map__sidebar">
              <div class="map-sidebar">
                <div class="map-sidebar__scrollable">
                  <div v-if="active_ipeds" class="map-sidebar__data">
                    <h2 class="map-sidebar__title">{{ active_institution["Institution Name"] }}</h2>
                    <div class="map-sidebar__stats" v-for="stat in getInstitutionStats(active_institution)" :key="stat['name']">
                      <span><strong>{{ stat['name'] }}</strong>: {{ format_stat_value(stat['value'], stat['name']) }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <h2 class="map-sidebar__title">{{ $t('sidebar.default_title') }}</h2>
                    <div class="map-sidebar__stats">
                      {{ $t('sidebar.default_desc') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="arrc-anchor-univ-map__zoom">-->
    <!--      <button title="zoom map out" v-on:click="leaflet_map.zoomOut()" class="zoom-button arrc-anchor-univ-map__zoom&#45;&#45;out"><span-->
    <!--          class="zoom-button__focus-control">–</span></button>-->
    <!--      <button title="zoom map in" v-on:click="leaflet_map.zoomIn()" class="zoom-button arrc-anchor-univ-map__zoom&#45;&#45;in"><span-->
    <!--          class="zoom-button__focus-control">+</span></button>-->
    <!--    </div>-->
  </div>
</template>
<script>

import data_file from '../data/anchor-univ-data.csv'
import config from '../config.json'

import L from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon
} from 'vue2-leaflet';

import map_marker from "../assets/images/map-marker.svg";
import selected_map_marker from "../assets/images/map-marker--selected.svg";

import 'leaflet/dist/leaflet.css'
// import leaflet_map_marker from 'leaflet/dist/images/marker-icon.png'


// const topojson = require('topojson');
// const d3 = require('d3');

export default {
  name: 'Map',
  data: () => {
    return {
      active_ipeds: null,
      active_institution: null,
      univ_data: [],
      leaflet_settings: {
        zoomControl: false,
        map_marker: map_marker,
        selected_map_marker: selected_map_marker,
        zoomSnap: 0.1,
        // staticAnchor: [13, 37],
        tileLayer: {
          url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
          subdomains: 'abcd',
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>.',
          maxZoom: 19,
        }
      },
      institution_template: config.institution_template
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  created() {
    this.univ_data = data_file;
  },
  methods: {
    log: function () {
      console.log(...arguments)
    },
    resetView: function() {
      this.setView()
    },
    setView: function (bounds) {
      bounds = bounds || L.latLngBounds(L.latLng(24, -128), L.latLng(51, -64))
      this.$nextTick(() => {
        this.$refs.arrc_anchor_univ_map.mapObject.fitBounds(bounds, {
          paddingTopLeft: [0, 0],
          paddingBottomRight: [530 + 32, 0]
        });
      });
    },

    activateInstitution: function(ipeds) {
      this.active_ipeds = ipeds;
    },
    getInstitutionStats: function(institution) {
      return Object.keys(institution)
          .map(key => {return {'name': key, 'value': institution[key]};})
          .filter(stat => config.allowed_sidebar_keys.indexOf(stat['name']) !== -1)
    },
    latlng: L.latLng,
    format_stat_value: function(stat_value, stat_name) {
      const yes_no_stats = [
        "Below State Average for Physican Ratio",
        "Above State Average in % Poor or Fair Health",
        "Hosts a Museum",
        "Hosts a Small Business Development Center"
      ]
      if (yes_no_stats.indexOf(stat_name) !== -1) {
        return (stat_value === 1) ? 'Yes' : 'No';
      }
      return stat_value;
    }
  },
  mounted() {
    this.resetView();
  },
  watch: {
    active_ipeds: function() {

      if (this.active_ipeds) {
        this.active_institution = this.univ_data.find(i => i.unitid === this.active_ipeds)
      }
      else {
        this.active_institution = this.institution_template;
      }
    },
  },
  computed: {
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@mixin transition($transition...) {
  transition: $transition;
}

@mixin transition-property($property...) {
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  transition-duration: $duration;
}

@mixin transition-timing-function($timing...) {
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  transition-delay: $delay;
}

@mixin nt($prop: all, $time: 0.25s, $anim: ease-in-out) {
  //
  // These are seperated to allow for multiple element selection with unquote('item, item2') syntax.
  // @include nt($el: unquote('width, opacity'));
  // You may also use single selectors.
  // @include nt($el: width);
  // Or none at all
  // @include nt;
  //
  @include transition-property($prop);
  @include transition-duration($time);
  @include transition-timing-function($anim);
}

@import './css_vars.scss';


@font-face {
  font-family: "Gotham";
  font-weight: 900;
  src: local("Gotham"), url(../assets/fonts/Gotham/GothamHTF-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(../assets/fonts/Gotham/GothamHTF-Book.otf) format("opentype");
}


.arrc-anchor-univ-map {
  font-family: "Gotham", "Montserrat", sans-serif;
  font-size: 14px;
  height: 80vh;
  min-height: 700px;
  max-height: 100vh;
  width: 100%;
  position: relative;
  color: $text-color;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .vue-slider {
    box-sizing: content-box;
  }

  h2, h3, h4, h5, h6, a, div, span, button {
    font-family: "Gotham", "Montserrat", sans-serif;
  }
}


.arrc-anchor-univ-map__map {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.arrc-anchor-univ-map__help-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  //margin: $sidebar-ext-padding $sidebar-ext-padding $sidebar-ext-padding 0;
  z-index: 4;
}


.button {
  background: $gradient;
  border: 0;
  border-radius: $border-radius / 2;
  padding: 6px 16px 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.arrc-anchor-univ-map__sidebar-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  //min-height: 50%;
  max-height: 100%;
  max-height: calc(100% - #{$sidebar-ext-padding * 2});
  width: $sidebar-size + $sidebar-ext-padding;
  margin: $sidebar-ext-padding $sidebar-ext-padding $sidebar-ext-padding 0;
  z-index: 2;
}

.arrc-anchor-univ-map__sidebar-border-outer-radius {
  //min-height: 50%;
  width: 100%;
  overflow: hidden;

  border-radius: $border-radius + $sidebar-border-thickness;
  //box-shadow: $default-box-shadow;
  z-index: 3;
  position: relative;
  background: $gradient;
}

.arrc-anchor-univ-map__sidebar-border-inner-radius {
  height: 100%;
  width: 100%;
  padding: $sidebar-border-thickness;
  overflow: hidden;

  box-shadow: $default-box-shadow;
  position: relative;
}

.arrc-anchor-univ-map__sidebar-border-inner-radius-background {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: $border-radius;
}

.arrc-anchor-univ-map__sidebar {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
}

.map-sidebar__stats {
  margin-bottom: 4px;
}

$drawer-tab-size: 50px;
$drawer-tab-padding: 12px;
$drawer-tab-icon-size: $drawer-tab-size - ($drawer-tab-padding * 2);

.legend-item {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
}

.legend-item__label {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
}

.locale-changer {
  position: absolute;
  top: $sidebar-int-padding + 5px;
  right: $sidebar-int-padding;
}

.filter--sector {
  tr:last-child {
    td {
      padding-top: 5px;
    }
  }
}

.arrc-anchor-univ-map__legend {
  position: absolute;
  left: 0;
  bottom: 0;
  //width: 500px;
  margin: 0 0;
  padding: 16px;
  z-index: 2;
  display: flex;
  flex-flow: row nowrap;
  border-top-right-radius: $border-radius;

  background: white;
  box-shadow: $legend-box-shadow;
}

.legend-item {
  display: flex;
  font-size: 14px;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
//$icon-size: 26px;

.legend-item__swatch {
  width: calc(var(--icon-size-default) - 2px);
  height: calc(var(--icon-size-default) - 2px);
  font-weight: bold;
  border: 0;
  border-radius: 50%;
  text-align: center;
  line-height: calc(var(--icon-size-default) - 2px);
  font-size: calc(var(--icon-size-default) - 12px);
  margin-right: 8px;
  margin-left: 6px;
  color: white;
  flex-shrink: 0;
}

.arrc-anchor-univ-map__zoom {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  margin: 0 0 32px 32px;
  height: 22px;
  background: white;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: $default-box-shadow;
  display: flex;

  button {
    border: 0;
    margin: 0;
    padding: 0 5px;
    font-size: 18px;
    display: block;
    width: 25px;
    //height: 20px;
    line-height: 1;
    background: transparent;
    cursor: pointer;
    height: 100%;

    &:hover {
      background: rgba(0, 0, 0, 0.1)
    }
  }

  button:first-of-type {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.zoom-button {
  position: relative;
}

.zoom-button__focus-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.map-sidebar__scrollable {
  padding: $sidebar-int-padding;
  position: relative;
}

.map-sidebar__title {
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin: 0.15rem 0 0.75rem;
  position: relative;
}

.map-sidebar__retreat {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: 0;
  line-height: 1;
  font-size: 18px;
  color: $blue;
  padding: 12px;
  cursor: pointer;
  font-weight: bold;
}

.map-sidebar__filters {
  display: flex;
  flex-flow: column nowrap;
  .map-sidebar__title {
    text-align: center;
    color: $teal;
  }
}

.map-sidebar__institution {
  .map-sidebar__title {
    color: $blue;
  }
}

.map-sidebar__compare {
  .map-sidebar__title {
    color: $blue;
  }
}

.map-sidebar__intro-text {
  font-size: 16px;
  text-align: left;
  font-weight: normal;
  margin: 0.25rem 0 0.75rem;
}

.map-sidebar__view-types {
  margin: 1rem 0 0.5rem;
}

.content-type-select__icons {
  display: flex;
  align-content: center;

  .content-type {
    border: 1px solid transparent;
    background-color: transparent;
    flex: 50% 0 0;
    padding: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:active {

    }
  }
}

.content-type__icon {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;

  .content-type__icon-image {
    opacity: 1;
    transition: 0.5s ease opacity;
  }
}

.content-type__icon--inactive {
  .content-type__icon-image--active {
    opacity: 0;
  }
}

.content-type:hover {
  .content-type__icon-image--active {
    opacity: 1;
  }
}

.content-type__icon--active {
  .content-type__icon-image--inactive {
    opacity: 0;
  }
}

.content-type__icon-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

/* map animations */
.fade-enter-to, .fade-leave {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
  transition-property: opacity, height, max-height;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.vert-slide-enter-to, .vert-slide-leave {
  opacity: 1;
  max-height: 1000px;
}

.vert-slide-enter-active, .vert-slide-leave-active {
  transition: opacity 0.5s ease;
  transition-property: opacity, height, max-height;
}

.vert-slide-enter, .vert-slide-leave-to /* .vert-slide-leave-active below version 2.1.8 */
{
  opacity: 0;
  max-height: 0px;
}


#arrc-anchor-univ-map__map {
  &:before,
  &:after {
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
    position: absolute;
  }

  &:before {
    content: 'Use ctrl + scroll to zoom the map';
    top: 50%;
    left: 30%;
    z-index: 999;
    font-size: 34px;
    transform: translate(-50%, -50%);
  }

  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    background: #000000;
    z-index: 999;
  }
}


#arrc-anchor-univ-map__map.map-scroll:before {
  opacity: 1;
}

#arrc-anchor-univ-map__map.map-scroll:after {
  opacity: 0.2;
}

.leaflet-pane > svg path.leaflet-interactive {
  transition: fill 0.25s ease;

  &:hover {
    //fill: $primary-color-hover
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// vue-select overrides

.vs__dropdown-option {
  white-space: normal;
  padding: 5px 10px;
}


// vue-slider-component overrides

.vue-slider-process {
  //box-shadow: 0 0 0 0px;
  background: $gradient;
}

</style>
